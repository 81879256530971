import router from '@/router'

const pagePathToRouteMap = {
  account: 'Index',
  caption: 'DocumentCaption',
  greeting: 'Greeting',
  lessons: 'Lessons',
  images: 'Images',
  regrets: 'Regrets',
  blessings: 'Blessings',
  closing: 'Closing',
}

const setUpLocalStorage = () => {
  if (!localStorage.getItem('storedData')) {
    localStorage.storedData = JSON.stringify({
      account: null,
      caption: null,
      greeting: null,
      lessons: null,
      images: null,
      regrets: null,
      blessings: null,
      closing: null,
    })
  }
}

const storeDataToLocalStorage = (path, data) => {
  let storedData = JSON.parse(localStorage.getItem('storedData'))
  storedData[path] = data
  localStorage.storedData = JSON.stringify(storedData)
}

const clearLocalStorage = () => {
  localStorage.removeItem('storedData')
}

const getAllLocalStorageData = () => {
  return JSON.parse(localStorage.getItem('storedData'))
}

const getDataFromLocalStorage = path => {
  let storedData = JSON.parse(localStorage.getItem('storedData'))
  for (let pagePath in storedData) {
    if (pagePath === path) {
      return storedData[path]
    }
    if (!storedData[pagePath]) {
      return router.push({ name: pagePathToRouteMap[pagePath] })
    }
  }
}

export default {
  getAllLocalStorageData,
  storeDataToLocalStorage,
  setUpLocalStorage,
  getDataFromLocalStorage,
  clearLocalStorage,
}
